import {create} from "zustand";
import {devtools} from "zustand/middleware";

export interface good {
    id: number,
    type_id ?: number,
    image: string | null,
    name: string,
    name_kz: string,
    description: string,
    quantity: number,
    price: number,
    originalPrice?: number
}

interface orderGood {
    id: number,
    quantity: number
}

interface createOrder{
    account: number,
    goods: orderGood[]
}

export interface searchGood extends good {
    type_name: string
}

export interface ProductType {
    id: string,
    name: string,
    discount: {
        id: number,
        discount_amount: string
    } | null
}

interface IState {
    goods: good[],
    cart: {
        goods: good[],
        original_total: number,
        total: number,
        discount_total: number
    },
    fridgeId: string,
    goodQuantity: number,
    lang: 'Ru' | 'Kz',
    searchGoods: { goods: searchGood[], status: 'not-loading' | 'loading' | 'loaded' | 'error' },
    productId: string,
    setLang: (lang:'Ru'|'Kz') => void,
    productTypes: ProductType[],
    activeProductType: string,
    increment: (id: number) => void,
    decrement: (id: number) => void,
    incrementInCart: (id: number) => void,
    decrementInCart: (id: number) => void,
    getCartGoods: () => void,
    getGoodQuantityInCart: () => void,
    fetchProductTypes: (fridgeId: string) => void,
    setActiveProductType: (id: string) => void,
    getGoods: (typeId:string, fridgeId: string) => void,
    fetchSearch: ({fridgeId, searching}: any) => void,
    fetchSearchLoading: () => void,
    setProductId: (id: string) => void,
    setSearchDataNull: () => void,
    setFridgeId: (fridgeId: string) => void,
    postOrder: (createOrder: createOrder) => void
}


export const useStore = create<IState>()(
    devtools(
        (set) => ({
            goods: [],
            cart: {
                goods: [],
                original_total: 0,
                total: 0,
                discount_total: 0
            },
            goodQuantity: 0,
            fridgeId: '',
            productTypes: [],
            productId: '',
            activeProductType: "",
            lang: 'Ru',
            searchGoods: {
                goods: [],
                status: 'not-loading'
            },
            increment: (id: number) => set((state) => ({
                goods: state.goods.map(good => good.id === id ? {
                    ...good,
                    quantity: good.quantity + 1
                } : good)
            }), false, 'increment'),
            decrement: (id: number) => set((state) => {
                const updatedGoods = state.goods.map(good =>
                    good.id === id ? { ...good, quantity: good.quantity > 0 ? good.quantity - 1 : 0 } : good
                );
                const cartGoods = state.cart.goods.filter(cartGood =>
                    cartGood.id !== id || (cartGood.id === id && cartGood.quantity > 1)
                );
                const total = cartGoods.reduce((sum, good) => sum + ((good.originalPrice ? good.originalPrice: good.price)  * good.quantity), 0);
                const discount_total = cartGoods.reduce((sum, good) => sum + (good.originalPrice ?((good.price - good.originalPrice)  * good.quantity) : 0), 0);
                const original_total = cartGoods.reduce((sum, good) => sum + ((good.price) * good.quantity), 0);
                return { goods: updatedGoods, cart: { goods: cartGoods, total: total, discount_total:discount_total, original_total: original_total } };
            }, false, 'decrement'),
            incrementInCart: (id: number) => set((state) => {
                const updatedCartGoods = state.cart.goods.map((good) =>
                    good.id === id ? { ...good, quantity: good.quantity + 1 } : good
                );
                const total = updatedCartGoods.reduce((sum, good) => sum + ((good.originalPrice ? good.originalPrice: good.price) * good.quantity), 0);
                const discount_total = updatedCartGoods.reduce((sum, good) => sum + (good.originalPrice ?((good.price - good.originalPrice)  * good.quantity) : 0), 0);
                const original_total = updatedCartGoods.reduce((sum, good) => sum + ((good.price) * good.quantity), 0);
                return { cart: { ...state.cart, goods: updatedCartGoods, total, discount_total, original_total }, goods: [] };
            }, false, 'incrementInCart'),
            decrementInCart: (id: number) => set((state) => {
                const updatedCartGoods = state.cart.goods
                    .map((good) =>
                        good.id === id ? { ...good, quantity: Math.max(0, good.quantity - 1) } : good
                    )
                    .filter(good => good.quantity > 0);
                const total = updatedCartGoods.reduce((sum, good) => sum + ((good.originalPrice ? good.originalPrice: good.price) * good.quantity), 0);
                const discount_total = updatedCartGoods.reduce((sum, good) => sum + (good.originalPrice ?((good.price - good.originalPrice)  * good.quantity) : 0), 0);
                const original_total = updatedCartGoods.reduce((sum, good) => sum + ((good.price) * good.quantity), 0);
                return { cart: { ...state.cart, goods: updatedCartGoods, total, discount_total, original_total }, goods: [] };
            }, false, 'decrementInCart'),
            getCartGoods: () => set((state) => {
                const cartGoods = state.goods.filter(good => good.quantity >= 1);
                const totalGoods = state.cart.goods.filter(good => !cartGoods.some(element => element.id === good.id));
                const fullGood = cartGoods.concat(totalGoods)
                const total = fullGood.reduce((sum, good) => sum + ((good.originalPrice ? good.originalPrice: good.price) * good.quantity), 0);
                const discount_total = fullGood.reduce((sum, good) => sum + (good.originalPrice ?((good.price - good.originalPrice)  * good.quantity) : 0), 0);
                const original_total = fullGood.reduce((sum, good) => sum + ((good.price) * good.quantity), 0);
                return { cart: { goods: fullGood, total: total, discount_total: discount_total, original_total } };
            }, false, 'getCartGoods'),
            getGoodQuantityInCart: () => set((state) => {
                const goods = state.cart.goods.filter((good: good) => good.quantity >= 1)
                return { goodQuantity: goods.length };
            }, false, 'getGoodQuantityInCart'),
            fetchProductTypes: async (fridgeId: string) => {
                const response = await fetch(`https://cabinet.vbox.kz/api/gettypes/?account=${fridgeId}`);
                const data = await response.json();
                const productTypes = data.types;
                set({
                    productTypes,
                    activeProductType: productTypes.length > 0 ? productTypes[0].id : ""
                });
            },
            setActiveProductType: (id: string) => set({ activeProductType: id }, false, 'setActiveProductType'),
            getGoods: async (typesId: string, fridgeId: string) => {
                const response = await fetch(`https://cabinet.vbox.kz/api/getproducts/?account=${fridgeId}&typesId=${typesId}`);
                const data = await response.json();
                if (data.comment === 'OK') {
                    let fetchedGoods = data.products.map((item: any) => ({
                        id: Number(item.id),
                        type_id: Number(typesId),
                        image: item.image_url,
                        name: item.name[0],
                        name_kz: item.name[1],
                        description: '',
                        quantity: 0,
                        price: Number(item.sum),
                        originalPrice: Number(item.sum_with_discount)
                    }));

                    set((state) => {
                        const existingCartGoods = state.cart.goods.filter(good => good.type_id === Number(typesId));
                        const updatedGoods = fetchedGoods.map((good: good) => {
                            const cartGood = existingCartGoods.find(cg => cg.id === good.id);
                            if (cartGood) {
                                return { ...good, quantity: cartGood.quantity };
                            }
                            return good;
                        });

                        return { goods: updatedGoods };
                    });
                } else {
                    set({ goods: [] });
                }
            },
            setLang: (lang) => set({ lang: lang }, false, 'setLang'),
            fetchSearch: async ({fridgeId, searching}) =>  {
                const response = await fetch(`https://cabinet.vbox.kz/api/search/?txn_id=1&account=${fridgeId}&search_term=`+encodeURIComponent(searching));
                const data = await response.json();
                if (data.comment === 'OK') {
                    let fetchedGoods = data.products.map((item: any) => ({
                        id: Number(item.id),
                        type_id: Number(item.type_id),
                        image: item.image_url,
                        name: item.name[0],
                        name_kz: item.name[1],
                        description: '',
                        quantity: 0,
                        type_name: item.type_name,
                        price: Number(item.price)
                    }));

                    set((state) => {
                        return { searchGoods: {goods: fetchedGoods, status: 'loaded'} };
                    });
                } else {
                    set({ searchGoods: {goods: [], status: 'error'} });
                }
            },
            fetchSearchLoading: () => set(() => ({searchGoods: {goods: [], status: 'loading'}}), false, 'fetchSearchLoading'),
            setProductId: (id) => set(() => ({productId: id}), false, 'setProductId'),
            setSearchDataNull: () => set(() => ({searchGoods: {goods: [], status: 'not-loading'}}), false, 'setSearchDataNull'),
            setFridgeId: (fridgeId) => set(() => ({fridgeId: fridgeId}), false, 'setFridgeId'),
            postOrder: async (createOrder: createOrder) => {
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json' // Type of content being sent
                    },
                    body: JSON.stringify(createOrder) // Converting the data to a JSON string
                };
                const response = await fetch(`https://cabinet.vbox.kz/api/createorder/`, options);
                const data = await response.json();
                const order_id = data.order_id;
                const kaspiPay = `https://kaspi.kz/pay/VBox?service_id=10401&15096=${order_id}`
                window.location.href = kaspiPay;

            }
        })
    )
)